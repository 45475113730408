let $header = null;
let mode = "fixed";
let prePointY = 0;

const initialize = () => {
  $header = $('div[data-view-target="headerRoot"]');
  mode = $header.data("style");
  $(document).ready(() => {
    exec();
  });
  $(window).resize(() => {
    exec();
  });
  $(window).scroll(() => {
    exec();
  });
};

const exec = () => {
  if (mode === "slide") {
    setPcSlideLayout();
  } else {
    setHeaderBlogLayout();
  }
};

// SPでスライドして上にあげていくと、少し細くなって固定される。
const setHeaderBlogLayout = () => {
  // const windowHeight = $(window).height();
  const currentPointY = $(window).scrollTop();
  const BreakpointSemi = 7;
  const Breakpoint = 16; // 64-48
  if (currentPointY > Breakpoint) {
    $header.addClass("style-fixed");
    $header.removeClass("style-semi-fixed");
  } else if (currentPointY > BreakpointSemi) {
    $header.removeClass("style-fixed");
    $header.addClass("style-semi-fixed");
  } else {
    $header.removeClass("style-semi-fixed");
    $header.removeClass("style-fixed");
  }
};

const setPcSlideLayout = () => {
  let currentPointY = $(window).scrollTop();
  // 下へ動いた
  if (prePointY < currentPointY) {
    $header.addClass("style-hide");
    // 上へ動いた
  } else {
    $header.removeClass("style-hide");
  }
  prePointY = currentPointY;
};

initialize();
